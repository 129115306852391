import React, { useState, useEffect } from "react";
import "./Body.css";
import phoneIconWhite from "../../images/icons/phoneIcon.svg";
import carpetLogo from "../../images/icons/carpetIcon.svg";
import tileLogo from "../../images/icons/tileIcon.svg";
import woodLogo from "../../images/icons/woodIcon.svg";
import concreteLogo from "../../images/icons/concreteIcon.svg";
import rugLogo from "../../images/icons/rugIcon.svg";
import upholsteryLogo from "../../images/icons/upholsteryIcon.svg";
import vehicleLogo from "../../images/icons/vehicleIcon.svg";
import addLogo from "../../images/icons/addIcon.svg";
import phoneIconBlue from "../../images/icons/phoneIconBlue.svg";
import emailIcon from "../../images/icons/emailIcon.svg";
import googleIcon from "../../images/icons/googleIcon.svg";
import facebookIcon from "../../images/icons/facebookIcon.svg";
import yelpIcon from "../../images/icons/yelpIcon.svg";
import nextdoorIcon from "../../images/icons/nextdoorIcon.svg";

const Body = (props) => {
  const [carpetFlip, setCarpetFlip] = useState(false);
  const [tileFlip, setTileFlip] = useState(false);
  const [woodFlip, setWoodFlip] = useState(false);
  const [concreteFlip, setConcreteFlip] = useState(false);
  const [rugFlip, setRugFlip] = useState(false);
  const [upholsteryFlip, setUpholsteryFlip] = useState(false);
  const [vehicleFlip, setVehicleFlip] = useState(false);
  const [addonFlip, setAddonFlip] = useState(false);

  const OpenSocialLink = (url) => {
    window.open(url, "_blank");
  };

  const FindImageInS3 = (imageName) => {
    console.log(
      "https://verdedesignimages.s3.amazonaws.com/azccc/" + imageName
    );
    return "https://verdedesignimages.s3.amazonaws.com/azccc/" + imageName;
  };

  return (
    <>
      {/* mobile view */}
      <div className="bodyMain whiteBackground blueText mobileView" id="home">
        <div className="marketingHeader">Your Local Flooring</div>
        <div className="marketingHeader">Care Specialists</div>
        <div className="callButtonSection">
          <a
            className="callButton redBackground whiteText"
            href={`tel:+19282037600`}
          >
            <img alt="" className="callButtonIcon" src={phoneIconWhite} />
            <div className="callButtonText">{"(928) 203-7600"}</div>
          </a>
        </div>
        <div className="scrollableRow">
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image1.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image2.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image3.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image4.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image5.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image6.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image7.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image8.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image9.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image10.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image11.png"
          ></img>
        </div>
        <a className="servicesAnchor" id="services"></a>
        <div className="sectionHeader blueText">Services</div>
        <div className="scrollableRow">
          <div className="serviceContainerMobile blueGreyBackground">
            <img alt="" className="serviceIcon" src={carpetLogo} />
            <div className="serviceTextHeaderMobile blueText">Carpet</div>
            <div className="serviceText blueText">50c per sq ft.</div>
            <div className="serviceText blueText">$2 per stair</div>
          </div>
          <div className="serviceContainerMobile blueGreyBackground">
            <img alt="" className="serviceIcon" src={tileLogo} />
            <div className="serviceTextHeaderMobile blueText">Tile</div>
            <div className="serviceText blueText">80c per sq ft.</div>
            <div className="serviceText blueText">$2 per stair</div>
          </div>
          <div className="serviceContainerMobile blueGreyBackground">
            <img alt="" className="serviceIcon" src={woodLogo} />
            <div className="serviceTextHeaderMobile blueText">Wood Floors</div>
            <div className="serviceText blueText">50c per sq ft.</div>
            <div className="serviceText blueText">$2 per stair</div>
          </div>
          <div className="serviceContainerMobile blueGreyBackground">
            <img alt="" className="serviceIcon" src={concreteLogo} />
            <div className="serviceTextHeaderMobile blueText">
              Concrete & Epoxy
            </div>
            <div className="serviceText blueText">65c per sq ft.</div>
            <div className="serviceText blueText">$2 per stair</div>
          </div>
          <div className="serviceContainerMobile blueGreyBackground">
            <img alt="" className="serviceIcon" src={rugLogo} />
            <div className="serviceTextHeaderMobile blueText">Area Rugs</div>
            <div className="serviceText blueText">Excludes jute</div>
            <div className="serviceText blueText">50c per sq ft.</div>
          </div>
          <div className="serviceContainerMobile blueGreyBackground">
            <img alt="" className="serviceIcon" src={upholsteryLogo} />
            <div className="serviceTextHeaderMobile blueText">Upholstery</div>
            <div className="serviceText blueText">Armchair $50</div>
            <div className="serviceText blueText">Loveseat $75</div>
            <div className="serviceText blueText">Sofa $100</div>
            <div className="serviceText blueText">Sectional $150</div>
          </div>
          <div className="serviceContainerMobile blueGreyBackground">
            <img alt="" className="serviceIcon" src={vehicleLogo} />
            <div className="serviceTextHeaderMobile blueText">Vehicles</div>
            <div className="serviceText blueText">Excludes leather</div>
            <div className="serviceText blueText">Custom Estimate</div>
          </div>
          <div className="serviceContainerMobile blueGreyBackground">
            <img alt="" className="serviceIcon" src={addLogo} />
            <div className="serviceTextHeaderMobile blueText">Add-Ons</div>
            <div className="serviceText blueText">Mildewcide</div>
            <div className="serviceText blueText">Deodorizer</div>
            <div className="serviceText blueText">Blacklighting</div>
            <div className="serviceText blueText">Carpet Protectant</div>
            <div className="serviceText blueText">Tile Sealing</div>
          </div>
        </div>
        <div className="serviceAreaSection">
          <div className="serviceAreaTextSection">
            <div className="serviceAreaHeader">We're proud to service:</div>
            <div className="serviceAreaLine">&#x2022; Camp Verde</div>
            <div className="serviceAreaLine">&#x2022; Clarkdale</div>
            <div className="serviceAreaLine">&#x2022; Cornville</div>
            <div className="serviceAreaLine">&#x2022; Cottonwood</div>
            <div className="serviceAreaLine">&#x2022; Lake Montezuma</div>
            <div className="serviceAreaLine">&#x2022; Rimrock</div>
            <div className="serviceAreaLine">&#x2022; Sedona/VOC</div>
            <div className="serviceAreaDisclaimer">
              We are not able to service properties in Jerome at this time.
            </div>
          </div>
          <div className="serviceAreaImageSection">
            <img
              alt=""
              className="serviceAreaImage"
              src="https://verdedesignimages.s3.amazonaws.com/azccc/serviceMapThin.png"
            ></img>
          </div>
        </div>
        <a className="aboutAnchor" id="about"></a>
        {/* this wrapped div prevents an odd white line on chrome */}
        <div className="blueBackground whiteText">
          <div className="sectionHeader">About</div>
          <div className="aboutSection">
            <div className="aboutPaul">
              <img
                alt=""
                className="aboutPaulImage"
                src="https://verdedesignimages.s3.amazonaws.com/azccc/paulPic.png"
              ></img>
              <div className="aboutPaulText">Paul Sinclair Jr.</div>
              <div className="aboutPaulText">Owner</div>
            </div>
            <div className="aboutText">
              <div className="aboutTextLine">
                At Certified Carpet Care, we are a family-owned and operated
                business dedicated to providing top-notch carpet cleaning and
                care services.
              </div>
              <div className="aboutTextLine">
                Since 2004, our mission has been to deliver exceptional results
                while fostering lasting relationships within our community.
              </div>
            </div>
          </div>
        </div>
        <a className="contactAnchor" id="contact"></a>
        <div className="contactSection greyBackground blueText">
          <div className="sectionHeader redText">Contact Us</div>
          <div className="contactLine">
            <a href={`tel:+19282037600`}>
              <img
                alt=""
                className="contactLineImage"
                src={phoneIconBlue}
              ></img>
            </a>
            <a className="contactLineText" href={`tel:+19282037600`}>
              <div>{"(928) 203-7600"}</div>
            </a>
          </div>
          <div className="contactLine">
            <a href="mailto:paul@azccc.net">
              <img alt="" className="contactLineImage" src={emailIcon}></img>
            </a>
            <a className="contactLineText" href="mailto:paul@azccc.net">
              <div>{"paul@azccc.net"}</div>
            </a>
          </div>
          <div className="socialButtons">
            <img
              alt=""
              className="socialButton"
              src={googleIcon}
              onClick={() =>
                OpenSocialLink("https://goo.gl/maps/pw7u9wtZMEougRws7")
              }
            />
            <img
              alt=""
              className="socialButton"
              src={facebookIcon}
              onClick={() =>
                OpenSocialLink("https://www.facebook.com/certifiedcarpet7600/")
              }
            />
            <img
              alt=""
              className="socialButton"
              src={yelpIcon}
              onClick={() =>
                OpenSocialLink(
                  "https://www.yelp.com/biz/certified-carpet-care-cottonwood"
                )
              }
            />
            {/* <img alt="" className="socialButton" src={nextdoorIcon} onClick={() => OpenSocialLink()}/> */}
          </div>
        </div>
        <div className="copyrightSection whiteBackground blueText">
          {"(c) Certified Carpet Care, All Rights Reserved"}
        </div>
      </div>

      {/* tablet view */}
      <div
        className="bodyMain whiteBackground blueText tabletView"
        id="homeTab"
      >
        <div className="marketingHeader">
          Your Local Flooring Care Specialists
        </div>
        <div className="callButtonSection">
          <a
            className="callButton redBackground whiteText"
            href={`tel:+19282037600`}
          >
            <img alt="" className="callButtonIcon" src={phoneIconWhite} />
            <div className="callButtonText">{"(928) 203-7600"}</div>
          </a>
        </div>
        <div className="scrollableRow">
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image1.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image2.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image3.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image4.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image5.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image6.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image7.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image8.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image9.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image10.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image11.png"
          ></img>
        </div>
        <a className="servicesAnchor" id="servicesTab"></a>
        <div className="sectionHeader blueText">Services</div>
        <div className="serviceCardRow">
          <div
            className={`serviceContainer blueGreyBackground ${
              carpetFlip ? "flip" : ""
            }`}
            onClick={() => setCarpetFlip(!carpetFlip)}
          >
            {carpetFlip ? (
              <>
                <div className="serviceTextHeaderBack blueText">Carpet</div>
                <div className="back">
                  <div className="serviceText blueText">50c per sq ft.</div>
                  <div className="serviceText blueText">$2 per stair</div>
                </div>
              </>
            ) : (
              <div className="front">
                <div className="serviceTextHeader blueText">Carpet</div>
                <img alt="" className="serviceIcon" src={carpetLogo} />
              </div>
            )}
          </div>
          <div
            className={`serviceContainer blueGreyBackground ${
              tileFlip ? "flip" : ""
            }`}
            onClick={() => setTileFlip(!tileFlip)}
          >
            {tileFlip ? (
              <>
                <div className="serviceTextHeaderBack blueText">Tile</div>
                <div className="back">
                  <div className="serviceText blueText">80c per sq ft.</div>
                  <div className="serviceText blueText">$2 per stair</div>
                </div>
              </>
            ) : (
              <div className="front">
                <div className="serviceTextHeader blueText">Tile</div>
                <img alt="" className="serviceIcon" src={tileLogo} />
              </div>
            )}
          </div>
          <div
            className={`serviceContainer blueGreyBackground ${
              woodFlip ? "flip" : ""
            }`}
            onClick={() => setWoodFlip(!woodFlip)}
          >
            {woodFlip ? (
              <>
                <div className="serviceTextHeaderBack blueText">
                  Wood Floors
                </div>
                <div className="back">
                  <div className="serviceText blueText">50c per sq ft.</div>
                  <div className="serviceText blueText">$2 per stair</div>
                </div>
              </>
            ) : (
              <div className="front">
                <div className="serviceTextHeader blueText">Wood Floors</div>
                <img alt="" className="serviceIcon" src={woodLogo} />
              </div>
            )}
          </div>
          <div
            className={`serviceContainer blueGreyBackground ${
              concreteFlip ? "flip" : ""
            }`}
            onClick={() => setConcreteFlip(!concreteFlip)}
          >
            {concreteFlip ? (
              <>
                <div className="serviceTextHeaderBack blueText">
                  Concrete & Epoxy
                </div>
                <div className="back">
                  <div className="serviceText blueText">65c per sq ft.</div>
                  <div className="serviceText blueText">$2 per stair</div>
                </div>
              </>
            ) : (
              <div className="front">
                <div className="serviceTextHeader blueText">
                  Concrete & Epoxy
                </div>
                <img alt="" className="serviceIcon" src={concreteLogo} />
              </div>
            )}
          </div>
        </div>
        <div className="serviceCardRow">
          <div
            className={`serviceContainer blueGreyBackground ${
              rugFlip ? "flip" : ""
            }`}
            onClick={() => setRugFlip(!rugFlip)}
          >
            {rugFlip ? (
              <>
                <div className="serviceTextHeaderBack blueText">Area Rugs</div>
                <div className="back">
                  <div className="serviceText blueText">Excludes jute</div>
                  <div className="serviceText blueText">50c per sq ft.</div>
                </div>
              </>
            ) : (
              <div className="front">
                <div className="serviceTextHeader blueText">Area Rugs</div>
                <img alt="" className="serviceIcon" src={rugLogo} />
              </div>
            )}
          </div>
          <div
            className={`serviceContainer blueGreyBackground ${
              upholsteryFlip ? "flip" : ""
            }`}
            onClick={() => setUpholsteryFlip(!upholsteryFlip)}
          >
            {upholsteryFlip ? (
              <>
                <div className="serviceTextHeaderBack blueText">Upholstery</div>
                <div className="back">
                  <div className="serviceText blueText">Armchair $50</div>
                  <div className="serviceText blueText">Loveseat $75</div>
                  <div className="serviceText blueText">Sofa $100</div>
                  <div className="serviceText blueText">Sectional $150</div>
                </div>
              </>
            ) : (
              <div className="front">
                <div className="serviceTextHeader blueText">Upholstery</div>
                <img alt="" className="serviceIcon" src={upholsteryLogo} />
              </div>
            )}
          </div>
          <div
            className={`serviceContainer blueGreyBackground ${
              vehicleFlip ? "flip" : ""
            }`}
            onClick={() => setVehicleFlip(!vehicleFlip)}
          >
            {vehicleFlip ? (
              <>
                <div className="serviceTextHeaderBack blueText">Vehicles</div>
                <div className="back">
                  <div className="serviceText blueText">Excludes leather</div>
                  <div className="serviceText blueText">Custom Estimate</div>
                </div>
              </>
            ) : (
              <div className="front">
                <div className="serviceTextHeader blueText">Vehicles</div>
                <img alt="" className="serviceIcon" src={vehicleLogo} />
              </div>
            )}
          </div>
          <div
            className={`serviceContainer blueGreyBackground ${
              addonFlip ? "flip" : ""
            }`}
            onClick={() => setAddonFlip(!addonFlip)}
          >
            {addonFlip ? (
              <>
                <div className="serviceTextHeaderBack blueText">Add-Ons</div>
                <div className="back">
                  <div className="serviceText blueText">Mildewcide</div>
                  <div className="serviceText blueText">Deodorizer</div>
                  <div className="serviceText blueText">Blacklighting</div>
                  <div className="serviceText blueText">Carpet Protectant</div>
                  <div className="serviceText blueText">Tile Sealing</div>
                </div>
              </>
            ) : (
              <div className="front">
                <div className="serviceTextHeader blueText">Add-Ons</div>
                <img alt="" className="serviceIcon" src={addLogo} />
              </div>
            )}
          </div>
        </div>
        <div className="serviceAreaSection">
          <div className="serviceAreaTextSection">
            <div className="serviceAreaHeader">We're proud to service:</div>
            <div className="serviceAreaLine">&#x2022; Camp Verde</div>
            <div className="serviceAreaLine">&#x2022; Clarkdale</div>
            <div className="serviceAreaLine">&#x2022; Cornville</div>
            <div className="serviceAreaLine">&#x2022; Cottonwood</div>
            <div className="serviceAreaLine">&#x2022; Lake Montezuma</div>
            <div className="serviceAreaLine">&#x2022; Rimrock</div>
            <div className="serviceAreaLine">&#x2022; Sedona/VOC</div>
            <div className="serviceAreaDisclaimer">
              We are not able to service properties in Jerome at this time.
            </div>
          </div>
          <div className="serviceAreaImageSection">
            <img
              alt=""
              className="serviceAreaImage"
              src="https://verdedesignimages.s3.amazonaws.com/azccc/serviceMap.png"
            ></img>
          </div>
        </div>
        <a className="aboutAnchor" id="aboutTab"></a>
        {/* this wrapped div prevents an odd white line on chrome */}
        <div className="blueBackground whiteText">
          <div className="sectionHeader">About</div>
          <div className="aboutSection">
            <div className="aboutPaul">
              <img
                alt=""
                className="aboutPaulImage"
                src="https://verdedesignimages.s3.amazonaws.com/azccc/paulPic.png"
              ></img>
              <div className="aboutPaulText">Paul Sinclair Jr.</div>
              <div className="aboutPaulText">Owner</div>
            </div>
            <div className="aboutText">
              <div className="aboutTextLine">
                At Certified Carpet Care, we are a family-owned and operated
                business dedicated to providing top-notch carpet cleaning and
                care services.
              </div>
              <div className="aboutTextLine">
                Since 2004, our mission has been to deliver exceptional results
                while fostering lasting relationships within our community.
              </div>
            </div>
          </div>
        </div>
        <a className="contactAnchor" id="contactTab"></a>
        <div className="contactSection greyBackground blueText">
          <div className="sectionHeader redText">Contact Us</div>
          <div className="contactLine">
            <a href={`tel:+19282037600`}>
              <img
                alt=""
                className="contactLineImage"
                src={phoneIconBlue}
              ></img>
            </a>
            <a className="contactLineText" href={`tel:+19282037600`}>
              <div>{"(928) 203-7600"}</div>
            </a>
          </div>
          <div className="contactLine">
            <a href="mailto:paul@azccc.net">
              <img alt="" className="contactLineImage" src={emailIcon}></img>
            </a>
            <a className="contactLineText" href="mailto:paul@azccc.net">
              <div>{"paul@azccc.net"}</div>
            </a>
          </div>
          <div className="socialButtons">
            <img
              alt=""
              className="socialButton"
              src={googleIcon}
              onClick={() =>
                OpenSocialLink("https://goo.gl/maps/pw7u9wtZMEougRws7")
              }
            />
            <img
              alt=""
              className="socialButton"
              src={facebookIcon}
              onClick={() =>
                OpenSocialLink("https://www.facebook.com/certifiedcarpet7600/")
              }
            />
            <img
              alt=""
              className="socialButton"
              src={yelpIcon}
              onClick={() =>
                OpenSocialLink(
                  "https://www.yelp.com/biz/certified-carpet-care-cottonwood"
                )
              }
            />
            {/* <img alt="" className="socialButton" src={nextdoorIcon} onClick={() => OpenSocialLink()}/> */}
          </div>
        </div>
        <div className="copyrightSection whiteBackground blueText">
          {"(c) Certified Carpet Care, All Rights Reserved"}
        </div>
      </div>

      {/* destop view */}
      <div
        className="bodyMain whiteBackground blueText desktopView"
        id="homeDesk"
      >
        <div className="marketingHeader">
          Your Local Flooring Care Specialists
        </div>
        <div className="callButtonSection">
          <a
            className="callButton redBackground whiteText"
            href={`tel:+19282037600`}
          >
            <img alt="" className="callButtonIcon" src={phoneIconWhite} />
            <div className="callButtonText">{"(928) 203-7600"}</div>
          </a>
        </div>
        <div className="scrollableRow">
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image1.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image2.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image3.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image4.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image5.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image6.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image7.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image8.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image9.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image10.png"
          ></img>
          <img
            alt=""
            className="marketingImage"
            src="https://verdedesignimages.s3.amazonaws.com/azccc/image11.png"
          ></img>
        </div>
        <a className="servicesAnchor" id="servicesDesk"></a>
        <div className="sectionHeader blueText">Services</div>
        <div className="servicesDesktopView">
          <div className="servicesDesktop1">
            <div className="serviceCardRow">
              <div
                className={`serviceContainer blueGreyBackground ${
                  carpetFlip ? "flip" : ""
                }`}
                onClick={() => setCarpetFlip(!carpetFlip)}
              >
                {carpetFlip ? (
                  <>
                    <div className="serviceTextHeaderBack blueText">Carpet</div>
                    <div className="back">
                      <div className="serviceText blueText">50c per sq ft.</div>
                      <div className="serviceText blueText">$2 per stair</div>
                    </div>
                  </>
                ) : (
                  <div className="front">
                    <div className="serviceTextHeader blueText">Carpet</div>
                    <img alt="" className="serviceIcon" src={carpetLogo} />
                  </div>
                )}
              </div>
              <div
                className={`serviceContainer blueGreyBackground ${
                  tileFlip ? "flip" : ""
                }`}
                onClick={() => setTileFlip(!tileFlip)}
              >
                {tileFlip ? (
                  <>
                    <div className="serviceTextHeaderBack blueText">Tile</div>
                    <div className="back">
                      <div className="serviceText blueText">80c per sq ft.</div>
                      <div className="serviceText blueText">$2 per stair</div>
                    </div>
                  </>
                ) : (
                  <div className="front">
                    <div className="serviceTextHeader blueText">Tile</div>
                    <img alt="" className="serviceIcon" src={tileLogo} />
                  </div>
                )}
              </div>
              <div
                className={`serviceContainer blueGreyBackground ${
                  woodFlip ? "flip" : ""
                }`}
                onClick={() => setWoodFlip(!woodFlip)}
              >
                {woodFlip ? (
                  <>
                    <div className="serviceTextHeaderBack blueText">
                      Wood Floors
                    </div>
                    <div className="back">
                      <div className="serviceText blueText">50c per sq ft.</div>
                      <div className="serviceText blueText">$2 per stair</div>
                    </div>
                  </>
                ) : (
                  <div className="front">
                    <div className="serviceTextHeader blueText">
                      Wood Floors
                    </div>
                    <img alt="" className="serviceIcon" src={woodLogo} />
                  </div>
                )}
              </div>
              <div
                className={`serviceContainer blueGreyBackground ${
                  concreteFlip ? "flip" : ""
                }`}
                onClick={() => setConcreteFlip(!concreteFlip)}
              >
                {concreteFlip ? (
                  <>
                    <div className="serviceTextHeaderBack blueText">
                      Concrete & Epoxy
                    </div>
                    <div className="back">
                      <div className="serviceText blueText">65c per sq ft.</div>
                      <div className="serviceText blueText">$2 per stair</div>
                    </div>
                  </>
                ) : (
                  <div className="front">
                    <div className="serviceTextHeader blueText">
                      Concrete & Epoxy
                    </div>
                    <img alt="" className="serviceIcon" src={concreteLogo} />
                  </div>
                )}
              </div>
            </div>
            <div className="serviceCardRow">
              <div
                className={`serviceContainer blueGreyBackground ${
                  rugFlip ? "flip" : ""
                }`}
                onClick={() => setRugFlip(!rugFlip)}
              >
                {rugFlip ? (
                  <>
                    <div className="serviceTextHeaderBack blueText">
                      Area Rugs
                    </div>
                    <div className="back">
                      <div className="serviceText blueText">Excludes jute</div>
                      <div className="serviceText blueText">50c per sq ft.</div>
                    </div>
                  </>
                ) : (
                  <div className="front">
                    <div className="serviceTextHeader blueText">Area Rugs</div>
                    <img alt="" className="serviceIcon" src={rugLogo} />
                  </div>
                )}
              </div>
              <div
                className={`serviceContainer blueGreyBackground ${
                  upholsteryFlip ? "flip" : ""
                }`}
                onClick={() => setUpholsteryFlip(!upholsteryFlip)}
              >
                {upholsteryFlip ? (
                  <>
                    <div className="serviceTextHeaderBack blueText">
                      Upholstery
                    </div>
                    <div className="back">
                      <div className="serviceText blueText">Armchair $50</div>
                      <div className="serviceText blueText">Loveseat $75</div>
                      <div className="serviceText blueText">Sofa $100</div>
                      <div className="serviceText blueText">Sectional $150</div>
                    </div>
                  </>
                ) : (
                  <div className="front">
                    <div className="serviceTextHeader blueText">Upholstery</div>
                    <img alt="" className="serviceIcon" src={upholsteryLogo} />
                  </div>
                )}
              </div>
              <div
                className={`serviceContainer blueGreyBackground ${
                  vehicleFlip ? "flip" : ""
                }`}
                onClick={() => setVehicleFlip(!vehicleFlip)}
              >
                {vehicleFlip ? (
                  <>
                    <div className="serviceTextHeaderBack blueText">
                      Vehicles
                    </div>
                    <div className="back">
                      <div className="serviceText blueText">
                        Excludes leather
                      </div>
                      <div className="serviceText blueText">
                        Custom Estimate
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="front">
                    <div className="serviceTextHeader blueText">Vehicles</div>
                    <img alt="" className="serviceIcon" src={vehicleLogo} />
                  </div>
                )}
              </div>
              <div
                className={`serviceContainer blueGreyBackground ${
                  addonFlip ? "flip" : ""
                }`}
                onClick={() => setAddonFlip(!addonFlip)}
              >
                {addonFlip ? (
                  <>
                    <div className="serviceTextHeaderBack blueText">
                      Add-Ons
                    </div>
                    <div className="back">
                      <div className="serviceText blueText">Mildewcide</div>
                      <div className="serviceText blueText">Deodorizer</div>
                      <div className="serviceText blueText">Blacklighting</div>
                      <div className="serviceText blueText">
                        Carpet Protectant
                      </div>
                      <div className="serviceText blueText">Tile Sealing</div>
                    </div>
                  </>
                ) : (
                  <div className="front">
                    <div className="serviceTextHeader blueText">Add-Ons</div>
                    <img alt="" className="serviceIcon" src={addLogo} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="servicesDesktop2">
            <div className="serviceAreaSection">
              <div className="serviceAreaTextSection">
                <div className="serviceAreaHeader">We're proud to service:</div>
                <div className="serviceAreaLine">&#x2022; Camp Verde</div>
                <div className="serviceAreaLine">&#x2022; Clarkdale</div>
                <div className="serviceAreaLine">&#x2022; Cornville</div>
                <div className="serviceAreaLine">&#x2022; Cottonwood</div>
                <div className="serviceAreaLine">&#x2022; Lake Montezuma</div>
                <div className="serviceAreaLine">&#x2022; Rimrock</div>
                <div className="serviceAreaLine">&#x2022; Sedona/VOC</div>
                <div className="serviceAreaDisclaimer">
                  We are not able to service properties in Jerome at this time.
                </div>
              </div>
              <div className="serviceAreaImageSection">
                <img
                  alt=""
                  className="serviceAreaImage"
                  src="https://verdedesignimages.s3.amazonaws.com/azccc/serviceMapThin.png"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <a className="aboutAnchor" id="aboutDesk"></a>
        <a className="contactAnchor" id="contactDesk"></a>
        <div className="contactAboutDesktopView">
          <div className="contactSection greyBackground blueText">
            <div className="contactDesktopSectionHeader redText">
              Contact Us
            </div>
            <div className="contactLine">
              <a href={`tel:+19282037600`}>
                <img
                  alt=""
                  className="contactLineImage"
                  src={phoneIconBlue}
                ></img>
              </a>
              <a className="contactLineText" href={`tel:+19282037600`}>
                <div>{"(928) 203-7600"}</div>
              </a>
            </div>
            <div className="contactLine">
              <a href="mailto:paul@azccc.net">
                <img alt="" className="contactLineImage" src={emailIcon}></img>
              </a>
              <a className="contactLineText" href="mailto:paul@azccc.net">
                <div>{"paul@azccc.net"}</div>
              </a>
            </div>
            <div className="socialButtons">
              <img
                alt=""
                className="socialButton"
                src={googleIcon}
                onClick={() =>
                  OpenSocialLink("https://goo.gl/maps/pw7u9wtZMEougRws7")
                }
              />
              <img
                alt=""
                className="socialButton"
                src={facebookIcon}
                onClick={() =>
                  OpenSocialLink(
                    "https://www.facebook.com/certifiedcarpet7600/"
                  )
                }
              />
              <img
                alt=""
                className="socialButton"
                src={yelpIcon}
                onClick={() =>
                  OpenSocialLink(
                    "https://www.yelp.com/biz/certified-carpet-care-cottonwood"
                  )
                }
              />
              {/* <img alt="" className="socialButton" src={nextdoorIcon} onClick={() => OpenSocialLink()}/> */}
            </div>
          </div>
          <div className="aboutDesktopSection">
            <div className="aboutDesktopSectionHeader blueBackground whiteText">
              About
            </div>
            <div className="aboutSection blueBackground whiteText">
              <div className="aboutPaul">
                <img
                  alt=""
                  className="aboutPaulImage"
                  src="https://verdedesignimages.s3.amazonaws.com/azccc/paulPic.png"
                ></img>
                <div className="aboutPaulText">Paul Sinclair Jr.</div>
                <div className="aboutPaulText">Owner</div>
              </div>
              <div className="aboutText">
                <div className="aboutTextLine">
                  At Certified Carpet Care, we are a family-owned and operated
                  business dedicated to providing top-notch carpet cleaning and
                  care services.
                </div>
                <div className="aboutTextLine">
                  Since 2004, our mission has been to deliver exceptional
                  results while fostering lasting relationships within our
                  community.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrightSection whiteBackground blueText">
          {"(c) Certified Carpet Care, All Rights Reserved"}
        </div>
      </div>
    </>
  );
};
export default Body;
