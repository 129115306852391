import React, { Component } from "react";
import TopNav from "./components/TopNav/TopNav.js";
import Body from "./components/Body/Body.js";
import "./App.css";
class App extends Component {
  render() {
    return (
      <>
        <TopNav />
        <Body />
      </>
    );
  }
}

export default App;
