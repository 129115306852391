import React, { useState, useEffect } from "react";
import "./TopNav.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import cccLogo from "../../images/icons/newLogoV2.svg";
import cccName from "../../images/icons/cccBrandName.png";
import phoneIcon from "../../images/icons/phoneIconRed.svg";
import hamburgerLogo from "../../images/icons/hamburgerIcon.svg";

const TopNav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const TriggerDial = () => {};

  const ToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <Navbar
        className={`topNavMain mobileNavView ${menuOpen ? "menuOpen" : ""}`}
        expand="lg"
        fixed="top"
      >
        <img alt="" className="cccNavLogo" src={cccLogo} />
        <div className="cccBrandName">
          <img alt="" className="brandNameText" src={cccName} href="/#home" />
        </div>
        <a href={`tel:+19282037600`}>
          <img
            alt=""
            className="navPhoneIcon"
            src={phoneIcon}
            onClick={TriggerDial}
          />
        </a>
        <img
          alt=""
          className="navHamburgerIcon extraMarginRight"
          src={hamburgerLogo}
          onClick={ToggleMenu}
        />
        {menuOpen ? (
          <>
            <div className="navDropdownMenu">
              <Nav.Link
                className="navMenuItem"
                href="/#services"
                onClick={ToggleMenu}
              >
                Services
              </Nav.Link>
              <Nav.Link
                className="navMenuItem"
                href="/#about"
                onClick={ToggleMenu}
              >
                About
              </Nav.Link>
              <Nav.Link
                className="navMenuItem"
                href="/#contact"
                onClick={ToggleMenu}
              >
                Contact Us
              </Nav.Link>
            </div>
            <div className="navDropdownBackground" />
          </>
        ) : (
          <></>
        )}
      </Navbar>

      <Navbar className="topNavMain nonMobileNavView" expand="lg" fixed="top">
        <img alt="" className="cccNavLogo" src={cccLogo} />
        <div className="cccBrandName">
          <img alt="" className="brandNameText" src={cccName} href="/#home" />
        </div>
        <Nav.Link className="navItem tab" href="/#homeTab">
          OUR WORK
        </Nav.Link>
        <Nav.Link className="navItem tab" href="/#servicesTab">
          SERVICES
        </Nav.Link>
        <Nav.Link className="navItem tab" href="/#aboutTab">
          ABOUT
        </Nav.Link>
        <Nav.Link className="navItem tab" href="/#contactTab">
          CONTACT
        </Nav.Link>
        <Nav.Link className="navItem desk" href="/#homeDesk">
          OUR WORK
        </Nav.Link>
        <Nav.Link className="navItem desk" href="/#servicesDesk">
          SERVICES
        </Nav.Link>
        <Nav.Link className="navItem desk" href="/#aboutDesk">
          ABOUT
        </Nav.Link>
        <Nav.Link className="navItem desk" href="/#contactDesk">
          CONTACT
        </Nav.Link>
        <a
          className="navCallButton redBackground whiteText"
          href={`tel:+19282037600`}
        >
          CALL US
        </a>
      </Navbar>
    </>
  );
};
export default TopNav;
